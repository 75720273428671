/**
 * @description - The guest guarding wrapper component. allowing to get access without authentication. If the user
 * logged in, then user will be redirected to the PATH_ROOTS_APP path.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// React
import { ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import { useAuth } from '../../hooks';
// Local components
import { LoadingScreen } from '../pages';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The wrapper component's property interface.
 */
type AuthGuardProps = {
  children: ReactNode;
  path: string;
};

/**
 * @description - The guest guarding wrapper component.
 * @param children
 * @param path
 * @constructor
 */
const AuthGuard = ({ children, path }: AuthGuardProps) => {
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={path} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}

export default AuthGuard;
