/**
 * @description - The documentation parties selection form component
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// form
import { useFormContext } from 'react-hook-form';
// @mui
import { Stack, Divider, Typography, Button } from '@mui/material';
// Local Hooks
import { useResponsive, useToggle, useAuth } from '../../../hooks';
// Local components
import { Iconify } from '../../index';
// Types
import { DocumentInvoiceContactForm } from '../types';
// Local components
import PartiesContactsListDialog from './PartiesContactsListDialog';
import {TypeDocumentInvoiceContactForm} from "../../../@types";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

function PartyInfo({ display_name, email, company_display_name }: DocumentInvoiceContactForm) {
  return (
    <>
      <Typography variant="subtitle2">{display_name ?? ''}</Typography>
      <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>Email: {email ?? ''}</Typography>
      {company_display_name && <Typography variant="body2">Company: {company_display_name}</Typography>}
    </>
  );
}

/**
 * @description - The PartiesContact Form view
 * @constructor
 */
export default function PartiesContactForm() {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { companies } = useAuth();

  const parseCompaniesContacts = () => {
    let contactsList: TypeDocumentInvoiceContactForm[] = [];
    if (companies !== undefined && companies.length > 0) {
      companies.map((company) => {
        contactsList.push({
          display_name: company.display_name,
          email: company.email,
          uid: company.uid,
        })
      })
    }
    return contactsList;
  }

  const upMd = useResponsive('up', 'md');
  const values = watch();

  const { toggle: openFrom, onOpen: onOpenFrom, onClose: onCloseFrom } = useToggle();

  const { toggle: openTo, onOpen: onOpenTo, onClose: onCloseTo } = useToggle();

  const { documentFrom, documentTo } = values;
  return (
    <Stack
      spacing={{ xs: 2, md: 5 }}
      direction={{ xs: 'column', md: 'row' }}
      divider={
        <Divider
          flexItem
          orientation={upMd ? 'vertical' : 'horizontal'}
          sx={{ borderStyle: 'dashed' }}
        />
      }
      sx={{ p: 3 }}
    >
      <Stack sx={{ width: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
          <Typography variant="h6" sx={{ color: 'text.disabled' }}>
            From:
          </Typography>

          <Button size="small" startIcon={<Iconify icon="eva:edit-fill" />} onClick={onOpenFrom}>
            Change
          </Button>

          <PartiesContactsListDialog
            open={openFrom}
            onClose={onCloseFrom}
            selected={(selectedId: string) => documentFrom?.uid === selectedId}
            onSelect={(document) => setValue('documentFrom', document)}
            contactsList={parseCompaniesContacts()}
          />
        </Stack>

        {documentFrom ? (
            <PartyInfo
              display_name={documentFrom.display_name}
              email={documentFrom.email}
              company_display_name={documentFrom.company_display_name}
            />
          ) : (
          <Typography typography="caption" sx={{ color: 'error.main' }}>
            {errors.documentFrom ? errors.documentFrom.message : null}
          </Typography>
          )
        }
      </Stack>
      <Stack sx={{ width: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
          <Typography variant="h6" sx={{ color: 'text.disabled' }}>
            To:
          </Typography>

          <Button
            size="small"
            startIcon={<Iconify icon={documentTo ? 'eva:edit-fill' : 'eva:plus-fill'} />}
            onClick={onOpenTo}
          >
            {documentTo ? 'Change' : 'Add'}
          </Button>

          <PartiesContactsListDialog
            open={openTo}
            onClose={onCloseTo}
            selected={(selectedId: string) => documentTo?.uid === selectedId}
            onSelect={(document) => setValue('documentTo', document)}
            contactsList={parseCompaniesContacts()}
          />
        </Stack>

        {documentTo ? (
          <PartyInfo
            display_name={documentTo.display_name}
            email={documentTo.email}
            company_display_name={documentTo.company_display_name} />
        ) : (
          <Typography typography="caption" sx={{ color: 'error.main' }}>
            {errors.documentTo ? errors.documentTo.message : null}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}
