/**
 * @description - The documentation parties selection form component
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// @mui
import { Dialog, ListItemButton, Stack, Typography, Button } from '@mui/material';
// Local types
import { TypeDocumentInvoiceContactForm } from '../../../@types';
// Local components
import { Iconify, Scrollbar } from '../../index';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

type Props = {
  open: boolean;
  selected: (selectedId: string) => boolean;
  onClose: VoidFunction;
  onSelect: (document: TypeDocumentInvoiceContactForm | null) => void;
  contactsList: TypeDocumentInvoiceContactForm[] | [];
};

export default function PartiesContactsListDialog({
  open,
  selected,
  onClose,
  onSelect,
  contactsList,
}: Props) {
  const handleSelect = (document: TypeDocumentInvoiceContactForm | null) => {
    onSelect(document);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 2.5, px: 3 }}
      >
        <Typography variant="h6"> Select entity </Typography>

        <Button
          size="small"
          variant="outlined"
          startIcon={<Iconify icon="eva:plus-fill" />}
          sx={{ alignSelf: 'flex-end' }}
        >
          Add New
        </Button>
      </Stack>

      <Scrollbar sx={{ p: 1.5, pt: 0, maxHeight: 80 * 8 }}>
        {contactsList.map((contact) => (
          <ListItemButton
            key={contact.uid ?? contact.email}
            selected={selected(contact.uid ?? contact.email)}
            onClick={() => handleSelect(contact)}
            sx={{
              p: 1.5,
              borderRadius: 1,
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography variant="subtitle2">{contact.display_name}</Typography>

            <Typography
              variant="caption"
              sx={{ color: 'primary.main', my: 0.5, fontWeight: 'fontWeightMedium' }}
            >
              {contact.email}
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {contact.company_display_name}
            </Typography>
          </ListItemButton>
        ))}
      </Scrollbar>
    </Dialog>
  );
}
