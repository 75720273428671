/**
 * @description - The Firebase Auth Context to provide authentication listener.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// React
import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState
} from 'react';
// Firebase instance type
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendSignInLinkToEmail,
  onAuthStateChanged,
  sendPasswordResetEmail
} from 'firebase/auth';
// Firestore
import {
  getFirestore,
  doc,
  getDocFromCache,
  getDoc,
  getDocs,
  query,
  where,
  collection,
  Timestamp
} from 'firebase/firestore';
// Firestore paths
import { userPaths } from '../../configs';

// ========================================================================== //
// ================================= Types ================================== //
// ========================================================================== //

/**
 * @description - User profile's type in the database
 */
export type UserProfile = {
  uid: string;
  account_type: string;
  created: Timestamp;
  updated: Timestamp;
  display_name: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  email: string;
  phone_number: string;
  photo_url: string;
};

/**
 * @description - The company profile that the account is related to
 */
type CompanyProfile = {
  uid: string;
  account_type: string;
  created: Timestamp;
  updated: Timestamp;
  display_name: string;
  email: string;
}

/**
 * @description - User Auth Type
 */
export type AuthUser = null | Record<string, any>;

/**
 * @description - Common Reducer Action Types that maps reducer payload and type
 * into object type for the use.
 */
export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

/**
 * @description - Enumeration for initialization
 */
enum Initialisation {
  Initial = 'INITIALISE'
}

/**
 * @description - The firebase Authentication Payload type
 */
type FirebaseAuthPayload = {
  [Initialisation.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};

/**
 * @description - Firebase Module's based Reducer Action Type
 */
export type FirebaseAuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
};

/**
 * @description - The Firebase Actions Type
 */
export type FirebaseActions =
  ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

/**
 * @description - Firebase Auth Context Type
 * @property isAuthenticated - Contains boolean of user authenticated status
 * @property isInitialized - Contains boolean of the user authentication process
 * status
 * @property login - Firebase login-password-password functionality
 * @property signWithEmailLink - Firebase login-via email link functionality
 * @property register - Firebase register functionality
 * @property logout - Firebase logout functionality
 * @property resetPassword - Firebase reset function functionality
 */
export type FirebaseAuthContext = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: UserProfile | undefined;
  companies: CompanyProfile[] | undefined;
  login: (email: string, password: string) => Promise<void>;
  signWithEmailLink: (email: string) => Promise<void>;
  register: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateProfile: VoidFunction;
  createProfile: VoidFunction;
};

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - Initial state for the Firebase Auth State that is passing into
 * reducer
 */
const initialState: FirebaseAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

/**
 * @description - Firebase Authentication Module based Redux's Reducer. The
 * reducer is dispatching the user profile update
 * @param state - Initial State of the reducer
 * @param action - Reducer actions
 * ToDo move the reducer to the redux/reducers directory
 */
const reducer = (state: FirebaseAuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

/**
 * @description - Firebase Auth Context
 */
export const ContextFirebaseAuth = createContext<FirebaseAuthContext | null>(
  null
);

/**
 * @description - Firebase Auth Provider hook
 * @param children - Children components
 * @constructor - Adding listener for the Firebase Auth State
 */
export function FirebaseAuthProvider({ children }: { children: ReactNode }) {
  // State of the Firebase Auth
  const [state, dispatch] = useReducer(reducer, initialState);
  // User profile state
  const [profile, setProfile] = useState<UserProfile | undefined>();
  const [companies, setCompanies] = useState<CompanyProfile[] | undefined>();

  /**
   * @description - The current Firebase Auth instance, that was Initialized in the
   * index.ts level. The method getFirebase is returning that Initialized instance
   */
  const authInstance = getAuth();

  /**
   * @description - The firestore db instance.
   */
  const firebaseDb = getFirestore();

  const getUserProfile = (userUid: string) => {
    // Defining the user's database reference
    const userDbReference = doc(
      firebaseDb,
      userPaths.COLLECTION_USERS,
      userUid
    );

    // Fetching current user info from the server side
    // getDocFromCache(userDbReference) // ToDo add this method instead get doc, because it is cached
    getDoc(userDbReference)
      .then((doc) => {
        if (doc.exists()) {
          const userProfileDocument = doc.data();
          // ToDo add profile parsing method
          setProfile({
            uid: userUid,
            account_type: userProfileDocument?.account_type ?? '', // If the user profile contains that field
            created: userProfileDocument?.created ?? '', // If the user profile contains that field
            updated: userProfileDocument?.updated ?? '', // If the user profile contains that field
            display_name: userProfileDocument?.display_name ?? '',
            first_name: userProfileDocument?.first_name ?? '', // If the user profile contains that field
            last_name: userProfileDocument?.last_name ?? '', // If the user profile contains that field
            middle_name: userProfileDocument?.middle_name ?? '', // If the user profile contains that field
            email: userProfileDocument?.email ?? '',
            phone_number: userProfileDocument?.phone_number ?? '',
            photo_url: userProfileDocument?.photo_url ?? null,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        // ToDo redirect to the profile can't read page and notify user
      });
  }

  /**
   * @description - The method is fetching companies profiles list
   * @param companiesList
   */
  const getCompaniesProfiles = (companiesList: string[]) => {
    if (companiesList?.length > 0) {
      // Getting companies list
      const companyDbReference = collection(
        firebaseDb,
        userPaths.COLLECTION_USERS
      );
      const companiesListQuery = query(
        companyDbReference,
        where('uid', 'in', companiesList.slice(0, 10))
      );
      getDocs(companiesListQuery).then((querySnapshot) => {
        const listOfCompanies: CompanyProfile[] = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data) {
            listOfCompanies.push({
              uid: data.uid,
              account_type: data.account_type,
              created: data.created,
              updated: data.updated,
              display_name: data.display_name,
              email: data.email,
            });
          }
        });
        if (listOfCompanies.length > 0) {
          setCompanies(listOfCompanies);
        }
      })
    }
  };

  // UseEffect Listener for the Firebase Auth State
  useEffect(
    () =>
      onAuthStateChanged(authInstance, (user) => {
        if (user && user.uid) {
          getUserProfile(user.uid);
          user.getIdTokenResult()
            .then((idTokenResult) => getCompaniesProfiles(idTokenResult?.claims?.companies as string[] ?? []));
          // getCompaniesProfiles(user.uid);
          // Updating global state of user authenticated status
          dispatch({
            type: Initialisation.Initial,
            payload: { isAuthenticated: true, user }
          });
        } else {
          // Updating global state of user authenticated status
          dispatch({
            type: Initialisation.Initial,
            payload: { isAuthenticated: false, user: null }
          });
        }
      }),
    [dispatch]
  );

  const getActionCodeSetting = () => {
    return {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: 'http://localhost:8111/apps?cartId=1234',
      // This must be true.
      handleCodeInApp: true,
      // ToDo add application level support
      iOS: {
        bundleId: 'com.example.ios'
      },
      android: {
        packageName: 'com.example.android',
        installApp: true,
        minimumVersion: '12'
      },
      dynamicLinkDomain: 'example.page.link'
    };
  };

  /**
   * @description - The method is executing sign in via email link using
   * firebase's auth with sendSignInLinkToEmail
   * @param email
   */
  const signWithEmailLink = (email: string) =>
    sendSignInLinkToEmail(authInstance, email, getActionCodeSetting())
      .then(() => {
        console.log('successfully sent');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });

  /**
   * @description - Firebase Login Method, authenticate user with email and pass
   * @param email - User email
   * @param password - User password
   */
  const login = (email: string, password: string) =>
    signInWithEmailAndPassword(authInstance, email, password)
      .then((userCredential) => {
        // ToDo handle user authed
        console.log('User', userCredential);
      })
      .catch((error) => {
        // ToDo handle user error authed
        console.log('Error', error);
      });

  /**
   * @description - Firebase Register Method, creates user with email and pass
   * @param email - User email
   * @param password - User password
   * @param firstName - User First Name
   * @param lastName - User Last Name
   */
  const register = (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) =>
    createUserWithEmailAndPassword(authInstance, email, password)
      .then((userCredentials) => {
        if (userCredentials?.user?.uid) {
          // Defining the users database reference
          const userDbReference = doc(
            firebaseDb,
            userPaths.COLLECTION_USERS,
            userCredentials.user.uid
          );
          // Writing user info into db
          // ToDo add the method
          // setDoc(userDbReference, {
          //
          // })
        } else {
          // ToDO handle the error
        }
      })
      .catch((error) => {
        // ToDO handle the error
      });

  const logout = async () => {
    await authInstance.signOut();
  };

  const resetPassword = async (email: string) => {
    await sendPasswordResetEmail(authInstance, email)
      .then(() => {
        // ToDo handle reset password
      })
      .catch((error) => {
        // ToDo handle reset password
      });
  };

  const auth = { ...state.user };

  /**
   * @description - The view of the Firebase Auth Context Hook method
   */
  return (
    <ContextFirebaseAuth.Provider
      value={{
        ...state,
        // ToDo update user populating profile staff
        user: profile,
        companies,
        login,
        signWithEmailLink,
        register,
        logout,
        resetPassword,
        // ToDo update user profile updating method
        updateProfile: () => {},
        createProfile: () => {}
      }}
    >
      {children}
    </ContextFirebaseAuth.Provider>
  );
}
