/**
 * @description - Routes variable for different platforms.
 * Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Paths
import { PATH_APP_ADMIN, PATH_APP_AUTH } from './index';
// Types
import { TypeRouteItem } from '../@types';
// Components
import { Loadable, AuthGuard } from '../components';
// Layouts
import { DashboardLayout } from '../layouts';
// Navigation configuration
import { adminNavBarConfig } from './index';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Authentication Pages
const AdminDashboardPage = Loadable(lazy(() => import('../pages/maintenance/index')));
const AdminProcessPage = Loadable(lazy(() => import('../pages/processes/AdminProcessesPage')));
const AdminDepartmentsListPage = Loadable(lazy(() => import('../pages/departments/DepartmentsListAdminPage')));
const AdminGroupsPage = Loadable(lazy(() => import('../pages/groups/AdminGroupsPage')));
const AdminTeamPage = Loadable(lazy(() => import('../pages/teams/AdminTeamsPage')));
const MaintenancePage = Loadable(lazy(() => import('../pages/maintenance/index')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Bisflow routes list, contains list of RouteItem type
 * objects
 */
const adminRoutes: TypeRouteItem = {
  path: PATH_APP_ADMIN.root,
  element: (
    <AuthGuard path={PATH_APP_AUTH.rootFull}>
      <DashboardLayout navConfig={adminNavBarConfig} />
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={PATH_APP_ADMIN.dashboardFull} replace />, index: true },
    // Dashboard
    {
      path: PATH_APP_ADMIN.dashboard,
      element: <AdminDashboardPage />
    },
    // Process section - REDIRECT -
    {
      path: PATH_APP_ADMIN.processes.root,
      element: <Navigate to={PATH_APP_ADMIN.processes.formsFull} replace />,
      index: true
    },
    // Process section - FORMS -
    {
      path: PATH_APP_ADMIN.processes.forms,
      element: <MaintenancePage />
    },
    // Process section - WORKFLOWS -
    {
      path: PATH_APP_ADMIN.processes.flows,
      element: <AdminProcessPage />
    },

    // Personnel section - REDIRECT -
    {
      path: PATH_APP_ADMIN.personnel.root,
      element: <Navigate to={PATH_APP_ADMIN.personnel.teamFull} replace />,
      index: true,
    },
    // Personnel section - TEAM -
    {
      path: PATH_APP_ADMIN.personnel.team,
      element: <AdminTeamPage />
    },
    // Personnel section - DEPARTMENTS -
    {
      path: PATH_APP_ADMIN.personnel.departments,
      element: <AdminDepartmentsListPage />
    },
    // Personnel section - DEPARTMENTS -
    {
      path: PATH_APP_ADMIN.personnel.groups,
      element: <AdminGroupsPage />
    },

    // Finances section - REDIRECT -
    {
      path: PATH_APP_ADMIN.finance.root,
      element: <Navigate to={PATH_APP_ADMIN.finance.paymentsFull} replace />,
      index: true
    },
    // Finances section - BUDGETS -
    {
      path: PATH_APP_ADMIN.finance.budgets,
      element: <MaintenancePage />
    },
    // Finances section - PAYMENTS -
    {
      path: PATH_APP_ADMIN.finance.payments,
      element: <MaintenancePage />
    },
    // Finances section - INVOICES -
    {
      path: PATH_APP_ADMIN.finance.invoices,
      element: <MaintenancePage />
    },

    // Inventory section - REDIRECT -
    {
      path: PATH_APP_ADMIN.inventory.root,
      element: <Navigate to={PATH_APP_ADMIN.inventory.goodsFull} replace />
    },
    // Inventory section - STOCKS -
    {
      path: PATH_APP_ADMIN.inventory.stocks,
      element: <MaintenancePage />
    },
    // Inventory section - GOODS -
    {
      path: PATH_APP_ADMIN.inventory.goods,
      element: <MaintenancePage />
    },

    // Providers section - REDIRECT -
    {
      path: PATH_APP_ADMIN.vendors.root,
      element: <Navigate to={PATH_APP_ADMIN.vendors.manageFull} replace />
    },
    // Providers section - STOCKS -
    {
      path: PATH_APP_ADMIN.vendors.manage,
      element: <MaintenancePage />
    },

    // Requests section - REDIRECT -
    {
      path: PATH_APP_ADMIN.requests.root,
      element: <Navigate to={PATH_APP_ADMIN.requests.goodsFull} replace />
    },
    // Requests section - STOCKS -
    {
      path: PATH_APP_ADMIN.requests.goods,
      element: <MaintenancePage />
    },

    // Documents section - REDIRECT -
    {
      path: PATH_APP_ADMIN.documents.root,
      element: <Navigate to={PATH_APP_ADMIN.documents.activeFull} replace />
    },
    // Documents section - BUDGETS -
    {
      path: PATH_APP_ADMIN.documents.authorizations,
      element: <MaintenancePage />
    },
    // Documents section - PAYMENTS -
    {
      path: PATH_APP_ADMIN.documents.active,
      element: <MaintenancePage />
    },
    // Documents section - INVOICES -
    {
      path: PATH_APP_ADMIN.documents.archive,
      element: <MaintenancePage />
    },

    // Reports section - REDIRECT -
    {
      path: PATH_APP_ADMIN.reports.root,
      element: <Navigate to={PATH_APP_ADMIN.reports.financialFull} replace />
    },
    // Reports section - BUDGETS -
    {
      path: PATH_APP_ADMIN.reports.procurement,
      element: <MaintenancePage />
    },
    // Reports section - PAYMENTS -
    {
      path: PATH_APP_ADMIN.reports.documents,
      element: <MaintenancePage />
    },
    // Reports section - INVOICES -
    {
      path: PATH_APP_ADMIN.reports.financial,
      element: <MaintenancePage />
    },

    // Analytics section - REDIRECT -
    {
      path: PATH_APP_ADMIN.analytics.root,
      element: <Navigate to={PATH_APP_ADMIN.analytics.financialFull} replace />
    },
    // Reports section - BUDGETS -
    {
      path: PATH_APP_ADMIN.analytics.procurement,
      element: <MaintenancePage />
    },
    // Reports section - PAYMENTS -
    {
      path: PATH_APP_ADMIN.analytics.documents,
      element: <MaintenancePage />
    },
    // Reports section - INVOICES -
    {
      path: PATH_APP_ADMIN.analytics.financial,
      element: <MaintenancePage />
    }
  ]
};

export default adminRoutes;
