/**
 * @description - The Documents application. Contains Documentations processing and management platforms.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// React
import { useRoutes } from 'react-router-dom';
// Contexts
import { BisflowThemeProvider } from '../contexts';
// Local components
import {
  NotistackProvider,
  MotionLazyContainer,
  ProgressBarStyle,
  Settings,
  ScrollTop,
} from '../components';
// Local types
import { TypeRouteItem } from '../@types';
// Routes
import { docsRoutes, authRoutes, mainRoutes } from '../routes';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - Application based related routes.
 */
const routes: TypeRouteItem[] = [ mainRoutes, authRoutes, docsRoutes ];

/**
 * @description - Bisflow application's administrative view.
 * @constructor
 */
export default function BisflowDocumentsApp() {
  return (
    <BisflowThemeProvider>
      <NotistackProvider>
        <MotionLazyContainer>
          <ProgressBarStyle />
          <Settings />
          <ScrollTop />
          {/* ToDo implement the Google Analytics */}
          { useRoutes(routes) }
        </MotionLazyContainer>
      </NotistackProvider>
    </BisflowThemeProvider>
  );
}
