import SvgIconStyle from './SvgIconStyle';
import {
  PeopleAltTwoTone,
  DomainTwoTone,
  LibraryBooksTwoTone,
  DeviceHubTwoTone,
  LocalGroceryStoreTwoTone,
} from '@mui/icons-material';

/**
 * @description - The method is getting svg icon from static directory and
 * wrapping into SvgIconStyle to return that object with the selected icon
 * @param name - icon name in the static directory
 */
const getSvgIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

/**
 * @description - The method is generating icon for side bar
 * @param Component
 */
const getIcon = (Component: any) => (
  <Component sx={{ width: 22, height: 22 }} />
);

export const icon_payments = getSvgIcon('ic_payment');
export const icon_authorizations = getSvgIcon('ic_authorization');
export const icon_documents = getSvgIcon('ic_documents');
export const icon_archive = getSvgIcon('ic_archive');
export const icon_requests = getSvgIcon('ic_request');
export const icon_invoices = getSvgIcon('ic_invoice');
export const icon_map = getSvgIcon('ic_map');
export const icon_box = getSvgIcon('ic_box');
export const icon_file = getSvgIcon('ic_file');
export const icon_budgets = getSvgIcon('ic_budgets');
export const icon_stock = getSvgIcon('ic_stock');
export const icon_blog = getSvgIcon('ic_blog');
export const icon_cart = getSvgIcon('ic_cart');
export const icon_chat = getSvgIcon('ic_chat');
export const icon_mail = getSvgIcon('ic_mail');
export const icon_page = getSvgIcon('ic_page');
export const icon_user = getSvgIcon('ic_user');
export const icon_copy = getSvgIcon('ic_copy');
export const icon_error = getSvgIcon('ic_error');
export const icon_charts = getSvgIcon('ic_charts');
export const icon_editor = getSvgIcon('ic_editor');
export const icon_upload = getSvgIcon('ic_upload');
export const icon_animate = getSvgIcon('ic_animate');
export const icon_calendar = getSvgIcon('ic_calendar');
export const icon_elements = getSvgIcon('ic_elements');
export const icon_carousel = getSvgIcon('ic_carousel');
export const icon_language = getSvgIcon('ic_language');
export const icon_ecommerce = getSvgIcon('ic_ecommerce');
export const icon_analytics = getSvgIcon('ic_analytics');
export const icon_dashboard = getSvgIcon('ic_dashboard');
export const icon_components = getSvgIcon('ic_components');
export const icon_authenticator = getSvgIcon('ic_authenticator');
export const icon_reports1 = getSvgIcon('ic_report');
export const icon_reports2 = getSvgIcon('ic_report_doc');
export const icon_store = getSvgIcon('ic_store');
export const icon_team = getIcon(PeopleAltTwoTone);
export const icon_department = getIcon(DomainTwoTone);
export const icon_forms = getIcon(LibraryBooksTwoTone);
export const icon_process = getIcon(DeviceHubTwoTone);
export const icon_procurement = getIcon(LocalGroceryStoreTwoTone);