/**
 * @description - The firestore database paths
 */

// User based
const COLLECTION_USERS = 'users';

// All paths
export const userPaths = {
  COLLECTION_USERS,
};

const COLLECTION_DOCUMENTS = 'documents';
const COLLECTION_CONTENTS = 'contents';
export const documentPaths = {
  COLLECTION_DOCUMENTS,
  COLLECTION_CONTENTS,
}
