// hooks
import { useAuth } from '../../hooks';
// utils
import createAvatar from '../../utils/createAvatar';
// Local components
import { Avatar } from './index';
import { TypeAvatarProps } from '../../@types';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: TypeAvatarProps) {
  const { user } = useAuth();
  const currentAvatar = createAvatar(user?.display_name ? user.display_name : 'BisFlow');

  return (
    <Avatar
      src={user?.photo_url}
      alt={user?.display_name}
      color={user?.photo_url ? 'default' : currentAvatar.color}
      {...other}
    >
      {currentAvatar.name}
    </Avatar>
  );
}
