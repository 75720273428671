/**
 * @description - The document metadata selection component form in order to select the document template and
 * other metadata.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import DatePicker from '@mui/lab/DatePicker';
import { Stack, TextField, MenuItem } from '@mui/material';
// components
import { RHFSelect } from '../../../components';
// Local types
import { TypeDocumentObject } from '../../../@types';
// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

interface DocumentMetadataSelectionFormProps {
  projects: { title: string, uid: string }[];
  docs: TypeDocumentObject[] | null | undefined;
}

/**
 * @description - The view of the document metadata selection
 * @param projects - The projects list of the view
 * @param docs - The projects list of the view
 * @constructor
 */
export default function DocumentMetadataSelectionForm({ projects, docs }: DocumentMetadataSelectionFormProps) {
  const { control } = useFormContext();

  const getDocumentsDropDown = () => {
    if (docs && docs.length > 0) {
      return (
        <RHFSelect
          fullWidth
          name="template_uid"
          label="Template"
          InputLabelProps={{ shrink: true }}
          SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
        >
          {docs.map((template) => (
            <MenuItem
              key={template.uid}
              value={template.uid}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {template.display_name}
            </MenuItem>
          ))}
        </RHFSelect>
      )
    }
  }

  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ p: 3, bgcolor: 'background.neutral' }}
    >
      <RHFSelect
        fullWidth
        name="project_uid"
        label="Project"
        InputLabelProps={{ shrink: true }}
        SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
      >
        {projects.map((project) => (
          <MenuItem
            key={project.uid}
            value={project.uid}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {project.title}
          </MenuItem>
        ))}
      </RHFSelect>

      {getDocumentsDropDown()}

      <Controller
        name="startDate"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            label="Start Date"
            value={field.value}
            onChange={(newValue) => {
              field.onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!error} helperText={error?.message} />
            )}
          />
        )}
      />

      <Controller
        name="endDate"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            label="End Date"
            value={field.value}
            onChange={(newValue) => {
              field.onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth error={!!error} helperText={error?.message} />
            )}
          />
        )}
      />
    </Stack>
  );
}
