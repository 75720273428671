/**
 * @description - The document list fetcher hook.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// React
import { useContext } from 'react';
import { ContextFirebaseDocuments } from '../contexts';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

const useDocuments = () => {
  const context = useContext(ContextFirebaseDocuments);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export default useDocuments;
