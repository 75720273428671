import { PATH_APP_DOCS } from './index';

// Icons
import {
  icon_elements,
  icon_file,
  icon_archive,
  icon_reports1,
  icon_analytics,
} from '../components';

export default [
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: PATH_APP_DOCS.dashboardFull,
        icon: icon_elements
      }
    ]
  },
  {
    subheader: 'files',
    items: [
      {
        title: 'active',
        path: PATH_APP_DOCS.active.rootFull,
        icon: icon_file,
        children: [
          {
            title: 'contracts',
            path: PATH_APP_DOCS.active.contractsFull
          },
          {
            title: 'acts',
            path: PATH_APP_DOCS.active.actsFull
          },
          {
            title: 'documents',
            path: PATH_APP_DOCS.active.documentsFull
          },
        ]
      },
      {
        title: 'archive',
        path: PATH_APP_DOCS.archive.rootFull,
        icon: icon_archive,
        children: [
          {
            title: 'contracts',
            path: PATH_APP_DOCS.archive.contractsFull
          },
          {
            title: 'acts',
            path: PATH_APP_DOCS.archive.actsFull
          },
          {
            title: 'documents',
            path: PATH_APP_DOCS.archive.documentsFull
          },
        ]
      },
    ]
  },
  {
    subheader: 'manage',
    items: [
      {
        title: 'reports',
        path: PATH_APP_DOCS.reports.rootFull,
        icon: icon_reports1,
        children: [
          {
            title: 'contracts',
            path: PATH_APP_DOCS.reports.contractsFull
          },
          {
            title: 'acts',
            path: PATH_APP_DOCS.reports.actsFull
          },
          {
            title: 'documents',
            path: PATH_APP_DOCS.reports.documentsFull
          }
        ]
      },
      {
        title: 'analytics',
        path: PATH_APP_DOCS.analytics.rootFull,
        icon: icon_analytics,
        children: [
          {
            title: 'contracts',
            path: PATH_APP_DOCS.analytics.contractsFull
          },
          {
            title: 'acts',
            path: PATH_APP_DOCS.analytics.actsFull
          },
          {
            title: 'documents',
            path: PATH_APP_DOCS.analytics.documentsFull
          }
        ]
      },
    ]
  }
]