/**
 * @description - The Main Layout view
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// React routing
import { Outlet } from 'react-router-dom';
// MUI
import { Box, Link, Container, Typography } from '@mui/material';
// Styles
import { MainLayoutStyle } from './mainLayoutStyles';
// Paths
import { PATH_FULL_LANDING } from '../../routes';
// Logo of the bisflow
import { LogoBisflowRed } from '../../components';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The main layout view
 * @constructor
 */
export default function MainLayout() {
  return (
    <>
      <MainLayoutStyle>
        <Outlet />
      </MainLayoutStyle>
      <Box
        sx={{
          py: 5,
          textAlign: 'center',
          position: 'relative',
          bgcolor: 'background.default',
        }}
      >
        <Container>
          <LogoBisflowRed sx={{ mb: 1, mx: 'auto' }} />

          <Typography variant="caption" component="p">
            © All rights reserved
            <br /> made by &nbsp;
            <Link href={PATH_FULL_LANDING}>Bisflow</Link>
          </Typography>
        </Container>
      </Box>
    </>
  );
}
